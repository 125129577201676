import NextLink from 'next/link';

import { Container, Link, SectionText } from '@/atoms';
import { Card, SwiperCustom } from '@/molecules';

import type { FunctionComponent } from 'react';
import type { TypeBenefitsProps } from './types';

/**
 * Benefits2
 */
export const Benefits: FunctionComponent<TypeBenefitsProps> = ({
  className = '',
  Cards,
  desc,
  title,
  caption,
  slidesPerView,
  slidesPerViewMobile,
  variant,
  backgroundColorTop,
  backgroundColorBottom,
  cutLevel = 50,
  cutLevelMobile = 50,
  backgroundColorTopMobile,
  backgroundColorBottomMobile,
  index,
  id,
  hasCutLevel,
}: TypeBenefitsProps) => (
  <Container
    id={id}
    index={index}
    backgroundColorTop={backgroundColorTop ?? '#fff'}
    backgroundColorBottom={backgroundColorBottom ?? '#fff'}
    cutLevel={cutLevel ?? 50}
    cutLevelMobile={cutLevelMobile ?? 50}
    backgroundColorBottomMobile={backgroundColorBottomMobile}
    backgroundColorTopMobile={backgroundColorTopMobile}
    className={`${className} flex flex-row gap-10 py-7 md:gap-14 md:py-10 lg:gap-20 lg:py-20`}
    overflows={true}
    hasCutLevel={hasCutLevel}
  >
    <div className="flex w-full  flex-col gap-6 overflow-visible md:gap-20 lg:flex-row">
      <SectionText
        direction="column"
        textAlign="start"
        variant={variant}
        title={title}
        desc={desc}
        caption={caption}
      />
      <div className="-mx-5 md:ml-0 lg:max-w-[66%]">
        <SwiperCustom
          direction="row"
          scrollbar={true}
          navigation={true}
          slidesPerView={slidesPerView}
          slidesPerViewMobile={slidesPerViewMobile}
          gap={16}
        >
          {Cards.map((child, index) => (
            <Card
              className={`!h-full md:ml-0 ${index === 0 ? 'ml-5' : ''} ${
                index === Cards.length - 1 ? 'mr-5' : ''
              }`}
              classNames={{ content: 'flex flex-col justify-between gap-4' }}
              key={index}
              {...child.cardProps}
            >
              <div className="flex flex-col gap-4 text-text-primary">
                <p className="text-subtitle text-subtitle--small">
                  {child.cardTitle}
                </p>
                <span className="text-body text-body--secondary font-light">
                  {child.cardDesc}
                </span>
              </div>
              {child.cardLink?.src && (
                <NextLink href={child.cardLink.src} passHref prefetch={false}>
                  <Link variant={variant} underline>
                    {child.cardLink.label}
                  </Link>
                </NextLink>
              )}
            </Card>
          ))}
        </SwiperCustom>
      </div>
    </div>
  </Container>
);

Benefits.displayName = 'Benefits';
